@import "variables";
// Override default variables before the import
//$body-bg: #000;
// Import Bootstrap and its default variables

.cursor-pointer {
  cursor: pointer;
}

.btn {
  line-height: 1 !important;
}

.errors {
  * {
    color: red !important;
  }
}

.entry-table {

  tr {
    background: #fff;

    th, td {
      padding: 10px;
    }

    &:nth-child(2n) {
      background: #ececec;
    }
  }
}

.small-gap {
  grid-gap: 10px !important;
}

.card {

  &:not(.no-shadow) {
    box-shadow: 0 7px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border: 1px solid #e3e3e3;
  }

  &.no-border {
    border: 0;
  }
}

.container {
  @media all and (max-width: 1780px){
    margin-left: auto !important;
    margin-right: 40px !important;
  }
}

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 1280px,
        xl: 1480px
);



#main {
  padding: 50px 0 0;
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.btn-min {
  font-size: 15px;
  font-weight: 600;
  color: $brand-primary;
}

$spacer: 20px;

$spacers: (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 2.5),
    6: ($spacer * 5),
    7: 70px,
    8: 80px,
    9: 90px,
    10: 100px,
    11: 110px,
    12: 120px,
    13: 130px,
    14: 140px,
    15: 150px,
    16: 15px,
    60: 60px
);

.text-row {
  background-color: rgb(232, 232, 232) !important;

  &:nth-child(3n) {
    margin-bottom: 40px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}


.muted {
  font-weight: 600;
  color: #858585;
}

.strong {
  font-weight: 600;
  color: #3a3a3c;
}

.meta-wrapper {
  font-size: 15px;
  display: block;
  line-height: 1;
}

.form-group {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.highlight {
  color: red;
}

.workingHoursTable {
  color: #858585;
  font-size: 15px;
  width: 50%;

  tr {

    &.active {
      th, td {
        font-weight: 700;
        color: #3a3a3c;
      }
    }
  }

  th {
    vertical-align: top;
    font-weight: 500;
  }

  td {
    font-weight: 600;
  }
}

.border-radius {
  border-radius: 5px !important;
}

.form-heading-wrapper {
  @extend .h2;
}

.form-paragraph-wrapper {
  font-size: 1rem;
}

.form-heading-wrapper, .form-paragraph-wrapper {
  .control-label {
    display: none;
  }
}

$modal-inner-padding: 1rem;

@import "~bootstrap/scss/bootstrap";
@import "scss/mixins/mixins";

.circle-count {
  width: 47px;
  height: 47px;
  background: #CCCCCC;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 50%;
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  letter-spacing: 0;
  color: #313F4E;
  font-size: 20px;
  font-weight: 600;
}

.text-decoration-none {
  text-decoration: none !important;
}

.mid-fixer {

  @include media-breakpoint-up(md) {
    margin: auto;
    transform: translateX(-50%);
  }
}

.copy-success {
  position: absolute;
  display: inline-block;
  line-height: 1;
  background: $brand-secondary;
  color: $body-color;
  font-weight: 600;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  padding: 4px 8px;
  border-radius: 5px;

  &:empty {
    display: none;
  }
}

.h-38 {
  height: 38px;
}

.btn-min {
  background: transparent;
  padding: 0;
  border: 0;
  color: $brand-primary;
}

.radio-inline {

  & > span {
    margin-right: 10px;

    input {
      margin-right: 5px;
    }
  }
}

.checkbox {
  input {
    margin-right: 5px;

  }
}

.form-paragraph-wrapper {

  p, span {
    font-size: 1rem !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
    font-weight: normal !important;
  }
}

.expenses-row {
  td,th {
    text-align: left;
  }
}

@for $i from 1 through 12 {
  .gallery-grid-#{$i} {
    @include columns(#{$i}, 20px);
  }
}

@for $i from 1 through 12 {
  .layout-grid-#{$i} {
    @include columns(#{$i}, 30px);
  }
}

@for $i from 1 through 12 {
  .gallery-columns-#{$i} {
    @include columns(#{$i}, 20px);
  }
}

@media (max-width: 1280px) {
  .layout-grid-1 {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}

.btn-secondary {
  color: #FFFFFF !important
}