@import "./src/scss/mixins/mixins";
@import "../../variables";
@import "~bootstrap/scss/bootstrap";

.home-bg {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-color: #002e54;
  position: relative;

  @include media-breakpoint-down(md) {
    @include justify-content(center);
  }
}

.home-links {
  grid-gap: 20px;
}

.subtitle {
  font-size: 21px;
  color: #3a3a3c;
  font-weight: 400;
}

.card {
  border-radius: 5px;
  border: 1px solid #e3e3e3;
  background-color: #f6f6f6;

  &:hover {
    text-decoration: none;
  }

  .h4 {
    color: #3a3a3c;
    font-size: 21px;
    font-weight: 600;
  }

  .subtitle {
    color: #3a3a3c;
    font-size: 15px;
    font-weight: 400;
  }
}

.logo-large {
  width: 360px;
  height: auto;
  max-width: 100%;

  path {
    fill: #00b4d9;
  }
}
