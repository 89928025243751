@import "./src/variables";

.navbar {

  .nav-link {
    font-weight: 600;
    color: $brand-primary;
  }
}

#user-dropdown {
  background: transparent;
  color: #212529;
  border: 0;
  padding: 0;

  &:focus {
    outline: 0;
    border: 0;
    box-shadow: none;
  }

  &:after {
    border: 0;
  }
}

.logo {
  &:hover {
    cursor: pointer;
  }
  img {
    object-fit: contain;
  }
}