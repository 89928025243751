.image {
  width: 100%;
}

.ship-data {
  flex: 1;
}

.ship-image {
  flex: 1;
}